import React from "react";
import { Container } from "react-bootstrap";

function ComingSoon() {
  return (
    <Container style={{ height: "90vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <h1 style={{
          color: "#ffffff", // Bright white text color
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.85)", // Black shadow for contrast
          fontSize: "4rem", // Larger text size for better visibility
          fontWeight: "bold", // Bold text for more impact
          textAlign: "center"
      }}>
        Coming Soon
      </h1>
    </Container>
  );
}

export default ComingSoon;
