import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/icons/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I am a Machine Learning Software Engineer currently working at <span className="purple">Aarki</span>, where I delve into the realms of <i><b className="purple">Machine Learning and Natural Language Processing</b></i>. 🤖
              <br />
              <br />I am particularly passionate about <b className="purple">Large Language Models (LLMs)</b> and continuously seek to stay at the forefront of innovations in AI.
              <br />
              <br />
              My technical expertise is broad and deep, encompassing advanced skills in <b className="purple">Python</b> and a robust proficiency in developing APIs using <b className="purple">FastAPI</b> and <b className="purple">Flask</b>. Additionally, I have substantial experience and knowledge in <b className="purple">Machine Learning</b> and <b className="purple">Natural Language Processing (NLP)</b>.
              <br />
              <br />
              I am driven by the challenge of integrating advanced AI technologies into practical, user-centric products and solutions.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/saireddy12"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/saireddy12"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/saireddy12/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/b.sai_reddy"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
