import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
        <p style={{ textAlign: "justify" }}>
          Hi, I am <span className="purple">Sai Kumar</span> from <span className="purple">Hyderabad, India.</span>
          <br />
          I am currently working as a Machine Learning Consultant at <span className="purple">Aarki</span>.
          <br />
          I completed my B.Tech in Computer Science, which laid the foundation for my career in technology.
          <br />
          <br />
          Apart from coding, some other activities that I love to do!
        </p>

          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Video Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Listening to Music 
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling(Bike Riding)
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
