import React from "react";
import { Col, Row } from "react-bootstrap";
import { DiPython, DiGit } from "react-icons/di";
import { SiPostgresql, SiFastapi, SiLinux } from "react-icons/si";
import { FaAws } from "react-icons/fa";
import { VscTerminalBash } from "react-icons/vsc";
// Import your custom SVGs
import MachineLearningIcon from '../../Assets/icons/machineLearning.svg';
import DlIcon from '../../Assets/icons/dl.jpg';
import NLPIcon from '../../Assets/icons/nlp.png';
function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <img src={MachineLearningIcon} alt="Machine Learning" style={{ width: '100%', height: 'auto' }} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={DlIcon} alt="Deep Learning" style={{ width: '100%', height: 'auto' }} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={NLPIcon} alt="Natural Language Processing" style={{ width: '100%', height: 'auto' }} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaAws />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFastapi />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiLinux />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <VscTerminalBash />
      </Col>
    </Row>
  );
}

export default Techstack;
