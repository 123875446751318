import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import eda from "../../Assets/Projects/eda.png"; 
import audiofp from "../../Assets/Projects/audio_fingerprint.jpg";
import scoring_img from "../../Assets/Projects/scoring.webp"
import appr_recd from "../../Assets/Projects/appr_recd.jpeg"
import quora from "../../Assets/Projects/quora.png"
import cancer_logo from "../../Assets/Projects/cancer_logo.png"

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works</strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few notable projects I've been involved with recently, showcasing my capabilities in machine learning, software engineering.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cancer_logo}
              isBlog={false}
              title="Personalized Cancer Diagnosis"
              description="Developed a multi-class classification system to accurately predict cancer based on genetic mutations from clinical evidence, significantly enhancing personalized treatment strategies."
              ghLink="https://github.com/saireddy12/Personalized-Cancer-Diagnosis/tree/master/Personalized-Cancer-Diagnosis"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={appr_recd}
              isBlog={false}
              title="Apparel Recommendation"
              description="Engineered a sophisticated recommendation engine that suggests similar apparel products using advanced machine learning techniques, based on multiple features such as title, brand, and color."
              ghLink="https://github.com/saireddy12/Apparel-Recommendation"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={quora}
              isBlog={false}
              title="Quora Question Pair Similarity"
              description="Developed a machine learning model to identify duplicate questions on Quora, enhancing user experience by streamlining the search for answers and improving content discoverability and reducing redundancy."
              ghLink="https://github.com/saireddy12/Quora-Question-Pair-similarity"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={eda}
              isBlog={false}
              title="Exploratory Data Analysis on Haberman Dataset"
              description="Performed in-depth exploratory data analysis to understand the correlation between patient survival statuses and treatment effects, contributing to improved clinical decisions."
              ghLink="https://github.com/saireddy12/Eda"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={audiofp}
              isBlog={false}
              title="Audio Fingerprinting and Text Recognition"
              description="Pioneered the development of cutting-edge audio fingerprinting and text recognition technologies to support innovative multimedia management solutions in industry settings."
              ghLink="https://github.com/saireddy12"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={scoring_img}
              isBlog={false}
              title="Pronunciation Scoring API"
              description="Developed an API to assess and score pronunciation in real-time, facilitating enhanced language learning experiences through immediate feedback and detailed performance metrics."
              ghLink="https://github.com/saireddy12"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
